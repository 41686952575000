import React from 'react';

import ReactPlayer from 'react-player/lazy';

const ProjectThree = () => {
    return (
        <div className={`section `} style={{padding:"0 0 170px 0"}}>
            <div className="container-fluid">
            
                <div className={`row test2`} >
             
                <div className='col-lg-4 mb-5'><ReactPlayer width='100%' url='https://www.youtube.com/watch?v=vncrSbREiSM' /></div>
                <div className='col-lg-4 mb-5'><ReactPlayer width='100%' url='https://www.youtube.com/watch?v=1U0eSqvP2eU' /></div>
                <div className='col-lg-4 mb-5'><ReactPlayer width='100%' url='https://www.youtube.com/watch?v=o1UOnWsk87o' /></div>
             
                </div>
               
            </div>
            </div>
    )
}

export default ProjectThree;