import React from 'react';
import FooterOne from '../common/footer/FooterOne';

import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerFour from '../component/banner/BannerFour';
import BlogOne from '../component/blog/BlogOne';

import Test from '../component/project/Test';
import Testi from '../pages/Testi';
import Services from '../component/project/Services';
import Edge from '../component/project/Edge'
import Youtube from '../component/project/Youtube'


const HomeStartup = () => {

    return (
        <>
        <SEO title="Interior Design"/>
  
        <main className="main-wrapper">
        <HeaderOne />
        <BannerFour />
        
        <Services/>
        {/* <ProjectFour /> */}
        {/* <Edge/> */}
        <Test/>
        <Testi/>
       
        
          
        <BlogOne />
        <Youtube/>
       
        <FooterOne />
        </main>
        </>
    )
}

export default HomeStartup;

