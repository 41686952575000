import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import BlogListOne from '../../component/blog/BlogListOne';





const BlogOne = () => {
    return (
        <div className="section section-padding-equal" style={{padding:"140px 0 30px 0"}}>
                
            <div className="container">
                <SectionTitle 
                    subtitle="What's Going On"
                    title="Latest Blog & Videos"
                   
                    textAlignment=""
                    textColor=""
                />
                <div className="row g-0">
                    <BlogListOne itemShow="2" />
                </div>

               

        
                
            </div>

            
            <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" /></li>
                <li className="shape shape-2" ><img src={process.env.PUBLIC_URL + "/images/others/line-10.png"} style={{opacity:"0.5"}} alt="bubble" /></li>
              
           
            </ul>
            
            

        </div>
        
    )
}

export default BlogOne;