import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';

import ProjectFive from '../component/project/ProjectFive';


const ProjectGridFive = () => {

    return (
        <>
        <SEO title="Project Full Width Four Column" />
       
        <main className="main-wrapper">
            
            <HeaderOne />
         
            <ProjectFive colSize ="row-cols-sm-2 row-cols-lg-3 row-cols-xl-4" parentClass="project-column-4" perPageShow="8"/>
      
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ProjectGridFive;