import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";

import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';





const BlogListOne = () => {

        const [posts, setPosts]  = useState([])

        const client = createClient({ space: "g8mtauguwlkr", accessToken: "hv9RIkQCvw4K5-0yUQEqz97ezGefVdSvdSn2gRny_Ac" })

        useEffect(() => {
            const getAllEntries = async () => {
              try {
                await client.getEntries({
                    content_type: 'blog',
                  }).then((entries) => {
                    console.log(entries.items)
                    setPosts(entries.items)
             

                })
              } catch (error) {
                console.log(`Error fetching authors ${error}`);
              }
            };
            getAllEntries()
          }, [])

          if (!posts) {
            return "Loading...";
          }



        
      
    return (
        <>
     

              {posts.slice(0, 2).map((post, index) => (
                <div className="col-xl-6" key={post.sys.id}>
                    <div className={`blog-list ${(index  == 1) ? "border-start" : ""}`}>
                      
                        <div className="post-thumbnail">
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${post.fields.title}`}>
                                <img src={post.fields.thumbnail.fields.file.url} alt="Blog posts" />
                            </Link>
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                                <Link to={process.env.PUBLIC_URL + `/blog-details/${post.fields.title}`}>
                                    {post.fields.title}
                                </Link>
                            </h5>
                            <p className='test3'>{post.fields.description.content[0].content[0].value}</p>
                            <Link to={process.env.PUBLIC_URL + `/blog-details/${post.fields.title}`} className="more-btn">
                                Read more <FaAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            ))} 

           
               


        </>
    )
}

export default BlogListOne;