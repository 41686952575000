import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';

import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import BlogSidebar from '../component/blog/BlogSidebar';
import { BLOCKS } from '@contentful/rich-text-types';

import SEO from '../common/SEO';
import {  FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



import { createClient } from "contentful";
import moment from 'moment'






const BlogDetails = () => {

    const params = useParams();
    const blogId = params.id;


    
    const [posts, setPosts]  = useState([])

    const client = createClient({ space: "g8mtauguwlkr", accessToken: "hv9RIkQCvw4K5-0yUQEqz97ezGefVdSvdSn2gRny_Ac" })

    useEffect(() => {
        const getAllEntries = async () => {
          try {
            await client.getEntries({
                content_type: 'blog',
              }).then((entries) => {
               
                setPosts(entries.items)
         

            })
          } catch (error) {
            console.log(`Error fetching authors ${error}`);
          }
        };
        getAllEntries()
      }, [])

      if (!posts) {
        return "Loading...";
      }
     
     const detailsBlog = posts.filter(blog => blog.fields.title === blogId);
      detailsBlog.map((post) => console.log(post))


      const renderOption = {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
            return (<img
              src={`https:${node.data.target.fields.file.url}`}
              height={node.data.target.fields.file.details.image.height}
              width={node.data.target.fields.file.details.image.width}
            />)
          }
        }
      }

  


    // const detailsBlog = getBlogData[0];
    // console.log(detailsBlog)
    const [toggler, setToggler] = useState(false);

    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
       
      };

    return (
        <>
        
 {detailsBlog.map((data) => (
      
            <main className="main-wrapper">
              <SEO title={data.fields.title}></SEO>
                <HeaderOne />
                <BreadCrumbOne 
                title={data.fields.title}
                page="Blog"
                />
                <div className="section-padding-equal">
                    <div className="container">
                        <div className="row row-40">
                            <div className="col-lg-8">
                                <div className="single-blog">
                                    <div className="single-blog-content blog-grid">
                                        <div className="post-thumbnail">
                                            
                                               
                                                   <img src={`${process.env.PUBLIC_URL}/${data.fields.thumbnail.fields.file.url}`} alt="Blog" />
                                            
                            

                                        </div>
                                        <div className="author">
                                            <div className="author-thumb">
                                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Blog Author" />
                                            </div>
                                            <div className="info">
                                                <h6 className="author-name">{data.fields.author}</h6>
                                                <ul className="blog-meta list-unstyled">
                                              
                                                    <li>{moment(data.sys.createdAt).format('DD-MM-YYYY HH:mm') }</li>
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                       {documentToReactComponents(data.fields.description, renderOption)}

                                       
                                    </div>
                                </div>
                                                
                              

                            </div>
                            <div className="col-lg-4">
                                <BlogSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>  
             ))} 
        </>
    )
}

export default BlogDetails;