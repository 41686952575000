import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../../utils';




const PropOne = ({projectStyle, portfolio}) => {
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail" >
				<Link to={process.env.PUBLIC_URL + `/project-details/${portfolio.fields.projectName}`}>
					<img src={process.env.PUBLIC_URL + portfolio.fields.thumbnail.fields.file.url} alt="icon" />
				</Link>
				</div>
				<div className="content">
				<h4 className="title"> 
					<Link to={process.env.PUBLIC_URL + `/project-details/${portfolio.fields.projectName}`}>{portfolio.fields.projectName}</Link>
				</h4>
				<span className="subtitle">
					
						<span>{portfolio.fields.location}, {portfolio.fields.year} </span>
				
				</span>
				</div>
			</div>
		</>
    )
}

export default PropOne;