import React from 'react';
import { Link } from 'react-router-dom';
import {  FaTwitter,FaLinkedin, FaInstagram,  FaPhoneAlt, FaEnvelopeOpen, FaFacebook, FaMapMarkerAlt } from "react-icons/fa";


const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area bg-color-light`} style={{padding:"2.5rem"}}>
            <div className="container">
       
                
                <div className="footer-main ">
             
                    <div className="row justify-content-center ">
                        <div className="col-xl-8">
                            <div className="row justify-content-center">
                                <div className="col-xl-8">
                                    <div className="footer-widget">
                                        <h6 className="widget-title text-white">Address</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled ">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}><FaMapMarkerAlt/> BSD City, Tangerang Selatan.</Link></li>
                                                <li> <Link to="#"><FaPhoneAlt/> 021-55699367</Link></li>
                                                <a href="mailto:interior@ruanga23.com"><FaEnvelopeOpen/> interior@ruanga23.com</a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="footer-widget">
                                        <h6 className="widget-title text-white">Social Networks</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled ">
                                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}><FaFacebook /> Facebook</Link></li>
                                                <li> <Link to={process.env.PUBLIC_URL + "/case-study"}><FaTwitter /> Twitter</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}><FaInstagram /> Instagram</Link></li>
                                                <li><Link to={process.env.PUBLIC_URL + "/project-grid-one"}><FaLinkedin /> Linkedin</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-xl-2">
                            <div className="footer-widget ">
                                <div className="footer-newsletter">
                                <img width={150} height={100} src={process.env.PUBLIC_URL + "/images/logo-2.png"}></img>
                                </div>
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
              
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copyright text-center">
                                <span className="copyright-text">© Hak Cipta Terpelihara PT. Ruang Alpha Nusantara.</span>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;