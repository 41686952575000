import React from 'react';

import { Link } from 'react-router-dom';



const BannerFour = () => {
    return (
        <div className="banner banner-style-4" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + "/images/banner/1.png"})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh'}}>
            <div className="container">
                <div className="banner-content">
                    <h1 className="title">Build Your Dream Room With Us</h1>
                    <p>Make Your Room Design Into Reality & Find Your Best Furniture With Us</p>
                    <div>
                        <Link to={process.env.PUBLIC_URL + "/project-grid-one"} className="axil-btn btn-fill-primary btn-large">View Projects</Link>
                    </div>
                </div>
                <div className="banner-thumbnail">
                    <div className="large-thumb">
                    {/* <img src={process.env.PUBLIC_URL + "/images/banner/1.png"} alt="Illustration"/> */}
                    </div>
                </div>
          
            </div>
           


        
           
        </div>
    )
}

export default BannerFour;