import React from 'react';

import ProjectData from "../../data/project/ProjectData.json";


const portfolioData = ProjectData;


const ProjectThree = () => {
    return (
        <div className={`section section-padding-equal pt_md--80 pt_sm--60`} style={{padding:"0 0 170px 0"}}>
            <div className="container-fluid">
            
                <div className={`row test2`}>
                
                      <div className="col-md-6" >
                      <a className="card3" href="#">
                          <img src={process.env.PUBLIC_URL + "images/banner/5.png"} alt="Card image cap"/>
                        
                          <p class="text-center">Test</p>
                         
                    
                          
                      </a>
                      </div>
                      
                      <div className="col-md-6" >
                      <a className="card3" href="#">
                          <img src={process.env.PUBLIC_URL + "images/banner/6.png"} alt="Card image cap" />
                        
                          <p class="text-center">Test</p>
                         
                    
                          
                      </a>
                      </div>

                      <div className="col-md-6" >
                      <a className="card3" href="#">
                          <img src={process.env.PUBLIC_URL + "images/banner/7.png"} alt="Card image cap" />
                        
                          <p class="text-center">Test</p>
                         
                    
                          
                      </a>
                      </div>

                      <div className="col-md-6" >
                      <a className="card3" href="#">
                          <img src={process.env.PUBLIC_URL + "images/banner/8.png"} alt="Card image cap" />
                        
                          <p class="text-center">Test</p>
                         
                    
                          
                      </a>
                      </div>
                   
              
                </div>
               
            </div>
            </div>
    )
}

export default ProjectThree;