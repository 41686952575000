import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import ReactPaginate from 'react-paginate';


import { createClient } from "contentful";
import moment from 'moment'





const BlogGridOne = () => {
    const [posts, setPosts]  = useState([])

        const client = createClient({ space: "g8mtauguwlkr", accessToken: "hv9RIkQCvw4K5-0yUQEqz97ezGefVdSvdSn2gRny_Ac" })

        useEffect(() => {
            const getAllEntries = async () => {
              try {
                await client.getEntries({
                    content_type: 'blog',
                  }).then((entries) => {
                    console.log(entries.items)
                    setPosts(entries.items)
             

                })
              } catch (error) {
                console.log(`Error fetching authors ${error}`);
              }
            };
            getAllEntries()
          }, [])

          if (!posts) {
            return "Loading...";
          }






    const [pageNumber, setPageNumber] = useState(0);

    const blogsPerPage = 4;
    const pageVisited = pageNumber * blogsPerPage;
    
    const pageCount = Math.ceil(posts.length / blogsPerPage);

    const changePage = ({selected}) => {
        setPageNumber(selected);
    }


    return (
        <>
            {posts.slice(pageVisited, pageVisited + blogsPerPage).map((data) => (
                <div className="blog-grid" key={data.sys.id}>
                    <h3 className="title">
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.fields.title}`}>{data.fields.title}</Link>
                    </h3>
                    <div className="author">
                        <div className="author-thumb">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Blog Author" />
                        </div>
                        <div className="info">
                            <h6 className="author-name">
                                {data.fields.author}
                            </h6>
                            <ul className="blog-meta list-unstyled">
                                <li>{moment(data.sys.createdAt).format('DD-MM-YYYY HH:mm') }</li>
                            
                            </ul>
                        </div>
                    </div>
                    <div className="post-thumbnail">
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}><img src={`${process.env.PUBLIC_URL}/${data.fields.thumbnail.fields.file.url}`} alt="Blog" /></Link>
                    </div>
                    <p className='test3'>{data.fields.description.content[0].content[0].value}</p>
                    <Link className="axil-btn btn-borderd btn-large" to={process.env.PUBLIC_URL + `/blog-details/${data.fields.title}`}>Read More</Link>
                </div>
            ))}

            <ReactPaginate
                previousLabel={<FaArrowLeft />}
                nextLabel={<FaArrowRight />}
                pageCount= {pageCount}
                onPageChange={changePage}
                containerClassName={"pagination justify-content-start"}
                previousLinkClassName={"prev"}
                nextLinkClassName={"next"}
                disabledClassName={"disabled"}
                activeClassName={"current"}
            />

        </>
    )
}

export default BlogGridOne;