import React from 'react';
import { Link } from 'react-router-dom';
import { slugify } from '../../../utils';


const PropOne = ({projectStyle, portfolio}) => {



	const mystyle = {
	
	}
	
    return (
		<>
			<div className={`project-grid ${projectStyle}`}>
				<div className="thumbnail">
				<Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}>
					<img src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
				</Link>
				</div>
				<div className="content">
				<span className="subtitle" 	style={{
        backgroundColor: "#109DA2",
    color:"white",
    padding: "10px",
    borderRadius: "5px",
	fontSize: "16px"
      }}>
					{portfolio.category.map((cat, i) => (
						<span key={i}>{cat}</span>
					))}
				</span>
				
				</div>
			</div>
		</>
    )
}

export default PropOne;