import React from "react";



import SectionTitle from "../../elements/section-title/SectionTitle";

const ProjectThree = () => {
  return (
    <>

    <div className={`section `} >
            <div className="container-fluid" style={{paddingLeft:"0", paddingRight:"0"}}>
            
            <div className="project-add-banner">
          
          <picture>
            <source
              className="thumbnail"
              srcset={process.env.PUBLIC_URL + "/images/project/vidoe2.webp"}
              style={{ maxHeight: "400px;" }}
            />
            <img src="/images/cereal-box.jpg" />
          </picture>
        </div>
               
            </div>
            </div>

    <div
      className="section section-padding-equal"
      style={{ padding: "60px 0 60px 0", backgroundColor:"var(--color-blue-shade)"}}
    >
      <div className="container">
        

        <SectionTitle
          subtitle="Projects"
          textAlignment="heading-light center"
          textColor=""
        />
<div className="cards">
        <div
          className="row mt-5 mb-5"
          style={{ margin: "0", paddingLeft: "0", justifyContent: "center" }}
        >
          <div className="col-lg-2 col-sm-4" style={{}}>
            <div className="card">
              <img
                className="card-img-top"
                src={process.env.PUBLIC_URL + "/images/project/banner/1.png"}
                alt="Card image cap"
              />
            
            </div>
            <h5 className="card-title text-center" style={{color:"black"}}>BEDROOM</h5>
                <p className="card-text text-center " style={{color:"black"}}>Industrial</p>
          </div>
          <div className="col-lg-2 col-sm-4" style={{}}>
            <div className="card">
              <img
                className="card-img-top"
                src={process.env.PUBLIC_URL + "/images/project/banner/4.png"}
                alt="Card image cap"
              />
            
            </div>
            <h5 className="card-title text-center" style={{color:"black"}}>OFFICE</h5>
                <p className="card-text text-center " style={{color:"black"}}>Industrial</p>
          </div>
          <div className="col-lg-2 col-sm-4" style={{}}>
            <div className="card">
              <img
                className="card-img-top"
                src={process.env.PUBLIC_URL + "/images/project/banner/2.png"}
                alt="Card image cap"
              />
            
            </div>
            <h5 className="card-title text-center" style={{color:"black"}}>KITCHEN</h5>
                <p className="card-text text-center " style={{color:"black"}}>Contempory</p>
          </div>
          <div className="col-lg-2 col-sm-4" style={{}}>
            <div className="card">
              <img
                className="card-img-top"
                src={process.env.PUBLIC_URL + "/images/project/banner/3.png"}
                alt="Card image cap"
              />
            
            </div>
            <h5 className="card-title text-center" style={{color:"black"}}>FAMILY ROOM</h5>
                <p className="card-text text-center " style={{color:"black"}}>Classic Modern</p>
          </div>
          <div className="col-lg-2 col-sm-4" style={{}}>
            <div className="card">
              <img
                className="card-img-top"
                src={process.env.PUBLIC_URL + "/images/project/banner/5.jpeg"}
                alt="Card image cap"
              />
            
            </div>
            <h5 className="card-title text-center" style={{color:"black"}}>STUDIO</h5>
                <p className="card-text text-center " style={{color:"black"}}>Play Full</p>
          </div>
        </div>
        </div>

        <SectionTitle
          subtitle="Our Services"
          textAlignment="heading-light center"
          textColor=""
        />

        <div className="container2" style={{marginBottom:"100px"}}>
          <div className="cards">
            <div className="row justify-content-center">
              <div className="col-lg-6 ">
                <div className="card4 card-one">
                  <h2 className="card-title">Design</h2>

                  <p className="description">
                    We provide different design services than others. With fresh
                    ideas resulting a unique design and surely fit your
                    characters.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="card4 card-two">
                  <h2 className="card-title">Build</h2>

                  <p className="description">
                    We also provide building services with a professional team,
                    working quicklym neatly and detailed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>





                 
<div className="row justify-content-center mt-5">
<div className="col-lg-4 ">
  <div className="project-add-banner2">
    <div className='service1' style={{backgroundColor:"#97BDA7"}}>
    <div className="test5">
     
     
      <img src={process.env.PUBLIC_URL + "/images/others/1.png"} />
    </div>
    <div className='titt' > 
    <h5 style={{ color: "white", textAlign:"center" }}>What our service excellence of desain?</h5>
      <ul>

      <li>Unique design</li>
      <li>Young designers with fresh ideas</li>
      <li>Design can adapt to your needs and budget with optimal results</li>
      <li>Open minded designer</li>
       
      </ul>
    </div>
    
</div>
  </div>
</div>

<div className="col-lg-4 ">
  <div className="project-add-banner2">
    <div className='service1' style={{backgroundColor:"#418D8D"}}>
    <div className="test5">
     
     
      <img src={process.env.PUBLIC_URL + "/images/others/2.png"} />
    </div>
    <div className='titt'> 
    <h5 style={{ color: "white", textAlign:"center" }}>What service excellence of our field works?</h5>
      <ul>
      <li>Transparent and detailed</li>
      <li>Professional</li>
      <li>Open discuss (negotiation)</li>
      <li>Best Price and special price</li>
      <li>Detailed, informative and easy to understand</li>
      </ul>
    </div>
   
</div>
  </div>
</div>

<div className="col-lg-4 ">
  <div className="project-add-banner2">
    <div className='service1' style={{backgroundColor:"#182F44"}}>
    <div className="test5">
     
     
      <img src={process.env.PUBLIC_URL + "/images/others/3.png"} />
    </div>
    <div className='titt'> 
    <h5 style={{ color: "white", textAlign:"center" }}>What service excellence of our field works?</h5>
      <ul>
      <li>Fast and neat work</li>
      <li>Discipline</li>
      <li>Orofessional</li>
      <li>Safe and reliable</li>
      <li>Details, so the result will be 99% same as design</li>

      </ul>
    </div>
    
</div>
  </div>
</div>

{/* 
<div className="col-lg-4 ">
  <div className="project-add-banner2">
    <div className="content">
      <h5 style={{ color: "white" }}>Our Edge in Budgeting</h5>
      <ul>
        <li> Transparent and detailed</li>
        <li> Professional</li>
        <li> Open discuss (negotiation)</li>
        <li> Best Price and special price</li>
        <li> Detailed, informative and easy to understand</li>
      </ul>
    </div>

    <picture>
      <source
        className="thumbnail"
        srcset={
          process.env.PUBLIC_URL + "/images/project/services1.png"
        }
        style={{ maxHeight: "400px;" }}
      />
      <img src="/images/cereal-box.jpg" />
    </picture>
  </div>
</div>

<div className="col-lg-4 ">
  <div className="project-add-banner2">
    <div className="content">
      <h5 style={{ color: "white" }}>Our Edge in Field Worker</h5>
      <ul>
        <li> Fast and neat work</li>
        <li> Discipline</li>
        <li> Professional</li>
        <li> Safe and reliable</li>
        <li> Details, so the result will be 99% same as design</li>
      </ul>
    </div>

    <picture>
      <source
        className="thumbnail"
        srcset={
          process.env.PUBLIC_URL + "/images/project/services1.png"
        }
        style={{ maxHeight: "400px;" }}
      />
      <img src="/images/cereal-box.jpg" />
    </picture>
  </div>
</div> */}
</div>



       
      </div>
    </div>
    </>
  );
};

export default ProjectThree;
