import React, { Component, useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';
import { withRouter } from "react-router";

import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import { slugify } from '../utils';
import ProjectData from "../data/project/ProjectData.json";
import VideoOne from '../component/video/VideoOne';
import Accordion from 'react-bootstrap/Accordion';
import { FaCompress, FaCode, FaGlobe } from 'react-icons/fa';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';

import { createClient } from "contentful";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import LightGallery from 'lightgallery/react';

import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import '../assets/fonts/Theseasons.otf'
import '../Index.css'


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "" }}
      onClick={onClick}
    />
  );
}


export default function ProjectDetails()  {

  const params= useParams()

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [posts, setPosts]  = useState([])

  const settings = {
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 5000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SamplePrevArrow />,
   

  };
 
  const settings2 = {
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 5000,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SamplePrevArrow />,
    variableWidth: true
   

  };
  

    const client = createClient({ space: "g8mtauguwlkr", accessToken: "hv9RIkQCvw4K5-0yUQEqz97ezGefVdSvdSn2gRny_Ac" })

    
    useEffect(() => {
      const getAllEntries = async () => {
        try {
          await client.getEntries({
              content_type: 'project',
            }).then((entries) => {
           
              setPosts(entries.items)
       

          })
        } catch (error) {
          console.log(`Error fetching authors ${error}`);
        }
      };
      getAllEntries()
    }, [])

    if (!posts) {
      return "Loading...";
    }
    

       const detailsBlog = posts.filter(blog => blog.fields.projectName === params.id);
       
  
  
        const renderOption = {
          renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
              return (<img
                src={`https:${node.data.target.fields.file.url}`}
                height={node.data.target.fields.file.details.image.height}
                width={node.data.target.fields.file.details.image.width}
              />)
            }
          }
        }
       

        
        return(
            detailsBlog.map((data , index) => (
              
           
              <main className="main-wrapper"  key={index}>
              <SEO title={data.fields.projectName}></SEO>
                  <HeaderOne />

                  <div className="breadcrum-area breadcrumb-banner single-breadcrumb">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className=" heading-left">
                            <h1 className="title h2" dangerouslySetInnerHTML={{__html: data.fields.title}}></h1>
                            <span className='mb-5' >
                               <h2 style={{fontSize: "50px"}}>{data.fields.style}</h2> <br></br>
                               <div style={{marginTop: "-55px", marginBottom:"15px", fontWeight: "500", fontSize: "20px", fontFamily: "var(--font-primary)", color: "var(--color-primary)"}}>{data.fields.category}</div><br></br>
                               Client Name : <b>{data.fields.clientName}</b><br></br>
                               Project  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: <b>{data.fields.projectName}</b><br></br>
                               Area   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  : {data.fields.size} <br></br>
                               Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {data.fields.location}<br></br>
                               Year    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {data.fields.year}<br></br>
                            </span>
                           
                            {documentToReactComponents(data.fields.highlight, renderOption)}
                           
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="banner-thumbnail" style={{marginTop:"40px"}}>
                       
                       
                       
                        
                        <div>
                   
                    <Slider {...settings} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>

                    {data.fields.gallery.map((gallery) => (
                   
                <a href={"https:"+gallery.fields.file.url} target="_blank">
                   <img alt="img1"  src={"https:"+gallery.fields.file.url}  />
                   
                   </a>
           
               
                 ))} 

                      
                    </Slider>
                 
                    
                  </div>
                            {/* <Tilty perspective={2000} reset={false}>
                                <img src={process.env.PUBLIC_URL + mainThumb} alt="Illustration" />
                            </Tilty> */}
                        </div>
                    </div>

                    <div className="col-lg-12">

                    <Slider  {...settings2}
                      asNavFor={nav1}
                      ref={(slider2) => setNav2(slider2)}
                      slidesToShow={4}
                      swipeToSlide={true}
                      focusOnSelect={true}
                    >
                      {data.fields.gallery.map((gallery) => (
                   
                
                   <img className="banner-project"style={{ width: 250 }} alt="img1" src={gallery.fields.file.url} />
                   
           
           
               
                 ))} 
                    </Slider>
                      </div>



                </div>
            </div>
            <ul className="shape-group-8 list-unstyled">
                <li className="shape shape-1">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-9.png"} alt="Bubble" />
                </li>
                <li className="shape shape-2">
                    <img src={process.env.PUBLIC_URL + "/images/others/bubble-20.png"} alt="Bubble" />
                </li>
                <li className="shape shape-3">
                    <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                </li>
            </ul>
        </div>

                 
                  <section className="section-padding single-portfolio-area">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="section-heading heading-left mb-0">
                                      {/* <span className="subtitle">
                                          
                                                  <span>{data.fields.projectName}</span> 
                                         
                                      </span>
                                      <h3 className="title">{data.fields.projectName}</h3> */}
                                  </div>
      
                               
      
                                  {documentToReactComponents(data.fields.projectDescription, renderOption)}
                                 
                              </div>
                              
                          </div>
                      </div>
                  </section>
                 
              <FooterOne parentClass="" />
              </main>
           )
           ));
      
    }