import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { createClient } from "contentful";
import moment from 'moment'



const WidgetPost = () => {

    const [posts, setPosts]  = useState([])

        const client = createClient({ space: "g8mtauguwlkr", accessToken: "hv9RIkQCvw4K5-0yUQEqz97ezGefVdSvdSn2gRny_Ac" })

        useEffect(() => {
            const getAllEntries = async () => {
              try {
                await client.getEntries({
                    content_type: 'blog',
                  }).then((entries) => {
                    console.log(entries.items)
                    setPosts(entries.items)
             

                })
              } catch (error) {
                console.log(`Error fetching authors ${error}`);
              }
            };
            getAllEntries()
          }, [])

          if (!posts) {
            return "Loading...";
          }


    return (
        <div className="post-list-wrap">
        
        {posts.slice(0, 3).map((data, index) => (
            <div className="single-post" key={index}>
                <div className="post-thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.fields.title}`}>
                        <img src={`${process.env.PUBLIC_URL}/${data.fields.thumbnail.fields.file.url}`} alt="Blog" />
                    </Link>
                </div>
                <div className="post-content">
                    <h6 className="title">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.fields.title}`}>
                        {data.fields.title}
                    </Link>
                    </h6>
                    <ul className="blog-meta list-unstyled">
                        <li>{moment(data.sys.createdAt).format('DD-MM-YYYY HH:mm') }</li>
                  
                    </ul>
                </div>
            </div>
        ))}
    </div>
    )
}

export default WidgetPost;


