import React, { Component } from "react";
import Slider from "react-slick";
import SectionTitle from "../elements/section-title/SectionTitle";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TestimonialData from "../data/testimonial/TestimonialData.json";

const allData = TestimonialData;

export default class AutoPlayMethods extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        className="section section-padding-equal"
        style={{ padding: "0 0 0 0" }}
      >
        <div className="container" style={{ textAlign: "center" }}>
          <SectionTitle
            subtitle="Testimonials"
            title="What people are saying"
            textAlignment=""
            textColor=""
          />

          <Slider
            className="mb-5"
            ref={(slider) => (this.slider = slider)}
            {...settings}
          >
            {allData.slice(0, 10).map((data, index) => (
              <figure className="snip1157">
                <img
                  src={process.env.PUBLIC_URL + data.authorimg}
                  alt="Ruang Alpha's Review"
                />
                <div className="author">
                  <h6>{data.authorname}</h6>
                  <span>{data.authordesig}</span>
                </div>
                <blockquote>
                  {data.description}
                  <div className="arrow" />
                </blockquote>
              </figure>
            ))}
          </Slider>

          <div className="footer-top">
            <div className="footer-social-link">
              <SectionTitle
                subtitle="Our Clients"
                textAlignment=""
                textColor=""
              />
              <ul
                className="list-unstyled"
                style={{ filter: "grayscale(100%)" }}
              >
                <li>
                  <img
                    width={150}
                    height={100}
                    src={process.env.PUBLIC_URL + "/images/project/kuncie.png"}
                  ></img>
                </li>
                <li>
                  <img
                    width={150}
                    height={100}
                    src={process.env.PUBLIC_URL + "/images/project/paloma.jpg"}
                  ></img>
                </li>
                <li>
                  <img
                    width={150}
                    height={100}
                    src={
                      process.env.PUBLIC_URL + "/images/project/rise_arrow.png"
                    }
                  ></img>
                </li>
                <li>
                  <img
                    width={150}
                    height={100}
                    src={process.env.PUBLIC_URL + "/images/project/pjb.png"}
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
